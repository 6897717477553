import React, { useState, useEffect } from "react";
import { Button, Flex, Space, Typography, Modal, Upload, Checkbox, Drawer, Select, Radio, Spin } from "antd";
import {
  UploadOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import file from "../../../img/file.svg";
import plus from "../../../img/plus.svg";
import arrowleft from "../../../img/arrowleftblue.svg";
import filter from "../../../img/filter.svg";
import { parseCSV, parseExcel } from "../../../utils/fileParsers";
import { uploadFileRequest, getRequest, deleteRequest, postRequest } from "../../../utils/apiHandler";
import Notiflix, { Notify } from "notiflix";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../../context/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
  setGoldenBatches,
  setLoading,
  setError,
  setSelectedBatchDetails,
} from "../../../Redux/slices/goldenBatchSlice";
import DeleteIcon from "../../../img/trash-icon.svg";
import ConfirmDeleteModal from "../../Modal/ConfirmDeleteModal";
import exclametryMark from '../../../img/exclametrymark.svg'
import FileMapping from "../../tables/FileMapping";
import { DynamicSubPlotConfig } from "../../charts/DynamicSubPlot";

const { Title, Paragraph } = Typography;

// Add this interface to handle the linter error
interface DynamicRow {
  Time: any;
  [key: string]: any;
}

interface ValidationOptions {
  processes: boolean;
  materials: boolean;
}

const extractColumnsFromCSV = (file: File): Promise<string[]> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const firstLine = text.split('\n')[0];
      const columns = firstLine.split(',').map(col => col.trim());
      resolve(columns);
    };
    reader.readAsText(file);
  });
};

// Add this helper function to extract required columns from system configs
const extractRequiredColumnsFromConfigs = (
  systems: any[], 
  options: ValidationOptions
): string[] => {
  const requiredColumns = new Set<string>();
  debugger
  if (systems?.length > 0) {
    Object.values(systems[0].config).forEach((config: any) => {
      // Extract process parameters if option is enabled
      if (options.processes && config.Processes) {
        Object.values(config.Processes).forEach((process: any) => {
          Object.keys(process.parameters || {}).forEach((param) => {
            requiredColumns.add(param);
          });
        });
      }

      // Extract material parameters if option is enabled
      if (options.materials && config.InputMaterial) {
        Object.entries(config.InputMaterial).forEach(([material, details]: [string, any]) => {
          if (details.quality && Array.isArray(details.quality)) {
            details.quality.forEach((param: string) => {
              requiredColumns.add(param);
            });
          }
        });
      }
    });
  }
  return Array.from(requiredColumns);
};


function DataTab({
  workbookId,
  uploadedFiles,
  setUploadedFiles,
  originalChartData,
  setOriginalChartData,
  setFilteredChartData,
  originalFileData,
  setOriginalFileData,
  filteredChartData,
  setFilteredFileData,
  setShowVisualization,
  setActiveTab,
  setDataContentSection,
  setDynamicPlotData,
  setDynamicPlotLayout
}: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<any>(null);
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [fileList, setFileList] = useState([]);
  const auth = useAuth();
  const dispatch = useDispatch();
  const { goldenBatches } = useSelector((state: any) => state.goldenBatch);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);
  const selectSystems = useSelector((state: any) => state.systems.systems);
  const [open, setOpen] = useState(false);
  const [draggedFile, setDraggedFile] = useState(null);
  const [materialList , setMaterialList] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const [fileTypes, setFileTypes] = useState<string[]>(['processes']);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false); // State to toggle date range visibility

  // Fetch file list on component mount
  useEffect(() => {

    // Check if the URL has 'tab=data' and does not have 'file=id'
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");
    const fileParam = params.get("file");

    if (tabParam === "data" && !fileParam) {
      fetchFileList();
    }
    fetchMaterialList()
  }, [selectSystems, location.search]);

  useEffect(() => {
    setFileList(uploadedFiles);
  }, [setUploadedFiles, uploadedFiles]);

  useEffect(() => {
    const savedFile = localStorage.getItem("selectedFile");
    if (savedFile) {
      const fileObject = JSON.parse(savedFile);
      handleFileDoubleClick(fileObject?.csv_id, fileObject?.file_name, fileObject);
    }
    const handleBeforeUnload = () => {
      localStorage.removeItem("selectedFile");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchFileList = async () => {
    try {
      setLoadingFiles(true);
      // const systemsNameString = selectSystems
      // ?.map((system: { name: any }) => system.name)
      // .join(",");
      const systemIdsString = selectSystems?.length ? selectSystems[0].systems
        ?.map((system: { systemId: number }) => {
          return system.systemId;
        })
        .join(",") : ''

      const apiUrl = systemIdsString
        ? `/file?systems_id=${systemIdsString}`
        : "/file";
      const response = await getRequest(apiUrl);
      if (response.data.status === 200) {
        setFileList(response.data.data.files);
        setUploadedFiles(response.data.data.files);
      }
    } catch (error: any) {
      if (error.status === 403) {
        Notiflix.Notify.failure(error.response.data.message);
        auth.logout();
        return;
      }
      Notiflix.Notify.failure("Failed to fetch file list");
    } finally {
      setLoadingFiles(false);
    }
  };
  const fetchMaterialList = async () =>{
    try {
      const response = await getRequest('/bucketization/material-wise-statistical-data')
      if (response.data.status === 200) {
        setMaterialList(response?.data?.data);
      }
      
    } catch (error) {
    console.log('error :', error);
      
    }
  }
  useEffect(() => {
    console.log("uploadedFilessss", uploadedFiles);
  }, [setUploadedFiles, uploadedFiles]);

  const handleFileUpload = async (file: any) => {
    // Check if file is CSV
    const allowedTypes = ["text/csv", "application/vnd.ms-excel"];
    if (!allowedTypes.includes(file.type) || !file.name.endsWith(".csv")) {
      Notiflix.Notify.failure("Please upload only CSV files");
      return false;
    }

    try {
      const columns = await extractColumnsFromCSV(file);
      const validationOptions = {
        processes: fileTypes.includes('processes'),
        materials: fileTypes.includes('material')
      };
      
      const requiredColumns = extractRequiredColumnsFromConfigs(selectSystems, validationOptions);
      const compatibility = auth.authState.user?.tenant_id === 6 
        ? true 
        : validateColumns(columns, requiredColumns);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("description", "File uploaded from frontend");
      formData.append("workflowId", workbookId);
      formData.append("systems", JSON.stringify(selectSystems[0]?.systems || []));
      formData.append("compatibility", compatibility?.toString());
      formData.append("type_of_file", JSON.stringify(fileTypes));

      const response = await uploadFileRequest("/file/upload", formData);
      if (response.data.status === 200) {
        Notiflix.Notify.success("File uploaded successfully");
        setUploadedFiles([]);
        setSelectedFile(null);
        setFileList([]);
        fetchFileList();
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      Notiflix.Notify.failure("Failed to upload file");
    }
    return false; // Prevent default upload behavior
  };

  const handleUploadAllClick = async () => {
    if (selectedFiles?.length === 0) {
      Notiflix.Notify.failure("No files selected for upload");
      return;
    }
    // selectedFiles.forEach((file) => handleFileUpload(file));
    await Promise.all(
      selectedFiles.map((file) => handleFileUpload(file))
    );

    setSelectedFiles([]);
  };

  const handleFileDoubleClick = async (csvId: string, fileName: string, file: any) => {
    try {
      if (file.compatibility == false) {
        if (!selectSystems[0]?.systems?.length) {
          Notify.warning("Please Select The Corresponding Systems of File", {
            timeout: 2000,
            distance: "50px",
            clickToClose: true,
          });
          return;
        }
        showDrawer(file);
        return;
      }
      localStorage.setItem("selectedFile", JSON.stringify(file));
      setSelectedFile(fileName);
      // setShowVisualization(false);
      setDataContentSection("data-visualisation");

      // Update URL with file ID
      navigate(`/?tab=data&file=${csvId}`);

      const response = await getRequest(`/file/${csvId}`);
      if (response.data.status === 200) {
        const fileData = response.data.data;

        // Validate and clean the data
        const cleanedData = fileData.map((row: any) => {
          const cleanRow = { ...row };
          Object.keys(cleanRow).forEach((key) => {
            // Only try to parse Time if it exists
            if (key === "Time" && cleanRow[key]) {
              // Keep Time as string for now
              cleanRow[key] = cleanRow[key];
            } else if (key === "DateTime" && cleanRow[key]) {
              cleanRow[key] = cleanRow[key];
            } else if (typeof cleanRow[key] === "string") {
              // Convert other string numbers to actual numbers
              const num = parseFloat(cleanRow[key]);
              cleanRow[key] = isNaN(num) ? cleanRow[key] ? cleanRow[key] : 0 : num;
            }
          });
          return cleanRow;
        });

        setOriginalFileData(cleanedData);
        setFilteredFileData(cleanedData);
        console.log("cleanedData", cleanedData);

        if (cleanedData?.length > 0) {
          // Get all columns except Time (if it exists)
          const columns = Object.keys(cleanedData[0]);
          setAvailableColumns(columns);
          setSelectedColumns(columns);
        }
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      Notiflix.Notify.failure("Failed to fetch file data");
      setSelectedFile(null);
      // setShowVisualization(true);
      setDataContentSection("file-listing");
      navigate('/?tab=data'); // Remove file from URL on error
    }
  };

  const handleGoldenParameter = () => {
    setDataContentSection("golden-parameters");
    const currentUrl = window.location.pathname;
    const queryParams = window.location.search;

    // Append '/golden-parameter' if it's not already at the end
    const newUrl = queryParams.endsWith("&golden-parameter=true") || queryParams.includes("&compare-golden-parameters=true")
      ? `${currentUrl}${queryParams}`
      : `${currentUrl}${queryParams}&golden-parameter=true`;

    window.history.pushState({}, "", newUrl);
  };
  const handleMaterialParameter = () =>{
    setDataContentSection("material-parameters");
    const currentUrl = window.location.pathname;
    const queryParams = window.location.search;

    // Append '/golden-parameter' if it's not already at the end
    const newUrl = queryParams.endsWith("&material-parameters=true") || queryParams.includes("&compare-golden-parameters=true")
      ? `${currentUrl}${queryParams}`
      : `${currentUrl}${queryParams}&material-parameters=true`;

    window.history.pushState({}, "", newUrl);
  }

  const showDeleteConfirmModel = (file: any) => {
    setShowConfirmDeleteModel(true);
    setFileToBeDeleted(file);
  };
  const showDrawer = (file: any) => {
    setOpen(true);
    setDraggedFile(file);
  };
  const onClose = () => {
    setOpen(false);
  };

  const renderFileList = () => {
    // First, organize files into a hierarchical structure
    const createFileHierarchy = (files: any[]) => {
      const fileMap = new Map();
      const rootFiles: any[] = [];

      // First pass: Create a map of all files
      files.forEach((file: any) => {
        file.children = [];
        fileMap.set(file.csv_id, file);
      });

      // Second pass: Organize into hierarchy
      files.forEach((file: any) => {
        if (file.parent_file_id && fileMap.has(file.parent_file_id)) {
          // Add as child to parent
          fileMap.get(file.parent_file_id).children.push(file);
        } else {
          // Add to root level if no parent or parent not found
          rootFiles.push(file);
        }
      });

      return rootFiles;
    };

    // Recursive component to render file items
    const FileItem = ({ file, level = 0 }: { file: any; level?: number }) => (
      <li className="group">
        <div
          className="flex gap-2 items-center relative"
        >
          {level > 0 && (
            <>
              <span
                className="absolute border-l border-gray-300"
                style={{
                  left: '-10px',
                  height: '100%',
                  top: '-50%'
                }}
              />

            </>
          )}
          <div className="inline-flex gap-1 items-center">
            {file.compatibility === false && (
              <img className="h-5" src={exclametryMark} alt="file" />
            )}
            <span
              style={{
                cursor: file.clustering_data_response === 'in_progress' ? 'not-allowed' : 'pointer',
                opacity: file.clustering_data_response === 'in_progress' ? 0.7 : 1
              }}
              onDoubleClick={() => {
                if (file.clustering_data_response !== 'in_progress') {
                  handleFileDoubleClick(file?.csv_id, file?.file_name, file)
                }
              }}
            >
              {file?.file_name}
              {file.clustering_data_response === 'in_progress' && (
                <Spin size="small" className="ml-2" />
              )}
            </span>
          </div>
          <button
            className="min-w-3 hidden group-hover:block"
            title="Delete"
            onClick={() => showDeleteConfirmModel(file)}
          >
            <img src={DeleteIcon} alt="Delete" className="size-3" />
          </button>
        </div>
        {file.children && file.children.length > 0 && (
          <ul className="pl-4 relative">
            {file.children.map((childFile: any) => (
              <FileItem
                key={childFile.csv_id}
                file={childFile}
                level={level + 1}
              />
            ))}
          </ul>
        )}
      </li>
    );

    const hierarchicalFiles = createFileHierarchy(fileList || []);

    return (
      <ul>
        {hierarchicalFiles.length > 0 ? (
          hierarchicalFiles.map((file: any) => (
            <FileItem key={file.csv_id} file={file} />
          ))
        ) : (
          <li>
            <span>No files uploaded</span>
          </li>
        )}
        <Drawer
          width={"40%"}
          title={
            <div className="flex flex-col gap-2">
              Match Missing Fields
              <span style={{ fontSize: "12px", color: "#888" }}>
                {" "}
                Map your CSV column with required fields
              </span>
              <div className="h-[5px] mt-2 bg-slate-600 bg-opacity-10 w-full rounded-md"></div>
            </div>
          }
          onClose={onClose}
          open={open}
          className="[&_.ant-drawer-body]:px-[30px] [&_.ant-drawer-header-title]:flex-row-reverse"
        >
          {open && (
            <FileMapping
              configurations={selectSystems}
              file={draggedFile}
              fetchUploadedFiles={fetchFileList}
              setOpen={setOpen}
            />
          )}
        </Drawer>
      </ul>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);

    console.log(" selectSystems selectSystems:", selectSystems);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemoveFile = (fileToRemove: any) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.uid !== fileToRemove.uid)
    );
  };

  const validateColumns = (
    fileColumns: string[],
    requiredColumns: string[]
  ) => {
    const missingColumns = requiredColumns.filter(
      (col) =>
        !fileColumns.some(
          (fileCol) => fileCol.toLowerCase() === col.toLowerCase()
        )
    );

    if (missingColumns.length > 0) {
      console.log("Missing required columns:", missingColumns);
      return false;
    }
    console.log("All required columns present");
    return true;
  };

  const uploadProps = {
    accept: ".csv",
    data11: "abc",
    beforeUpload: async (file: any) => {
      // const columns = await extractColumnsFromCSV(file);

      // // Get required columns from selected systems
      // const requiredColumns = extractRequiredColumnsFromConfigs(selectSystems);

      // // Validate columns
      // if (!validateColumns(columns, requiredColumns)) {
      //   Notiflix.Notify.failure(
      //     `File is missing required columns: ${requiredColumns.join(', ')}`
      //   );
      //   return false;
      // }

      setSelectedFiles((prev) => [...prev, file]);
      return false;
    },
    onChange: (info: any) => {
      if (info.file.status === "error") {
        console.error("Upload failed:", info.file.error);
      }
    },
    onRemove: (file: any) => {
      handleRemoveFile(file);
    },
  };

  const processData = (data: any[]) => {
    if (!data.length) return;

    const allCategories: string[] = [];
    const seriesMap: Record<string, number[]> = {};
    const dataTableData: any[] = [];
    const limitedSeries: any[] = [];
    const limitedCategories: string[] = [];
    const limit = 8000;

    data.sort((a, b) => {
      const dateA = parseDate(a.Time);
      const dateB = parseDate(b.Time);
      return dateA.getTime() - dateB.getTime();
    });

    data.forEach((item: any, indx) => {
      const dateTime = parseDate(item.Time);
      if (isNaN(dateTime.getTime())) return;

      allCategories.push(dateTime.toISOString());
      if (limit >= indx) {
        dataTableData.push(item);
        Object.entries(item).forEach(([key, value]) => {
          if (key !== "Time") {
            const trimmedKey = key.trim();
            const parsedValue = parseFloat(value as string);

            if (!seriesMap[trimmedKey]) {
              seriesMap[trimmedKey] = [];
              limitedCategories.push(trimmedKey);
            }

            seriesMap[trimmedKey].push(isNaN(parsedValue) ? 0 : parsedValue);
            let singleData = limitedSeries.find((s) => s.name === trimmedKey);

            if (singleData) {
              singleData.data.push({
                x: dateTime,
                y: isNaN(parsedValue) ? 0 : parsedValue,
              });
            } else {
              limitedSeries.push({
                name: trimmedKey,
                data: [
                  {
                    x: dateTime,
                    y: isNaN(parsedValue) ? 0 : parsedValue,
                  },
                ],
              });
            }
          }
        });
      }
    });

    setOriginalFileData(dataTableData); // Store the original file data
    const newChartData = {
      timeSeries: { categories: limitedCategories, series: limitedSeries },
    };
    setOriginalChartData(newChartData); // Store original chart data
    setFilteredChartData(newChartData); // Set filtered data to the new chart data
    setFilteredFileData(dataTableData); // Initially set the filtered data to the original data
  };

  const parseDate = (dateString: string) => {
    const parts = dateString.split(" ");
    if (parts.length === 2) {
      const dateParts = parts[0].split("/");
      const timeParts = parts[1].split(":");

      if (dateParts.length === 3 && timeParts.length === 2) {
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        let year = parseInt(dateParts[2], 10);

        if (year < 100) {
          year += year < 50 ? 2000 : 1900;
        }

        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        return new Date(year, month, day, hours, minutes);
      }
    }
    return new Date(NaN);
  };

  const handleBackClick = () => {
    setSelectedFile(null);
    // setShowVisualization(true);
    setDataContentSection("file-listing");
    localStorage.removeItem("selectedFile");
    setDynamicPlotData([]); // Clear dynamic plot data
    setDynamicPlotLayout([]); // Clear dynamic plot layout
    navigate('/?tab=data'); // Remove file from URL when going back
  };

  const handleColumnChange = (checkedValues: string[]) => {
    setSelectedColumns(checkedValues);
    const newFilteredData = originalFileData.map((row: any) => {
      const newRow: DynamicRow = { Time: row.Time };
      checkedValues.forEach((col) => {
        newRow[col] = row[col];
      });
      return newRow;
    });
    setFilteredFileData(newFilteredData);
  };

  const renderGoldenBatchList = () => (
    <div>
      <ul>
        {goldenBatches.length > 0 ? (
          goldenBatches?.slice(0, 5)?.map((batch: any) => (
            <li
              key={batch.id}
              onDoubleClick={() => handleGoldenBatchClick(batch)}
              style={{ cursor: "pointer" }}
            >
              <span>{batch.name}</span>
            </li>
          ))
        ) : (
          <li>
            <span>No Golden Batch available</span>
          </li>
        )}
      </ul>
      <ul>
        {goldenBatches?.length > 5 ? (
          <span
            onClick={handleGoldenParameter}
            className="flex items-center text-blue-500 cursor-pointer hover:text-blue-700 transition duration-300"
          >
            View More
          </span>
        ) : (
          <li></li>
        )}
      </ul>
    </div>
  );

  const renderMaterialBatchList = () => (
    <div>
      {materialList.map((res:any, index) => (
        <div className="data-outer" key={index}>
          <div className="connect-data active">
            <img src={file} alt="file" />
            {
              res.input_material_type?.map((inputMaterial:any)=>(
            <div>
                <span>  {inputMaterial.replace(/([a-z])([A-Z])/g, '$1 $2')}  </span>
              </ div>
              ))
            }
          </div>
          <ul>
        {res?.data?.length > 0 ? (
          res?.data?.slice(0, 5)?.map((batch: any) => (
            <li
              key={batch.id}
              onDoubleClick={()=>setDataContentSection("material-parameters")}
              style={{ cursor: "pointer" }}
            >
              <span>{batch.name}</span>
            </li>
          ))
        ) : (
          <li>
            <span>No Material available</span>
          </li>
        )}
      </ul>
        </div>
      ))}
      <ul>
        {materialList?.length > 5 ? (
          <span
            onClick={()=>setDataContentSection("material-parameters")}
            className="flex items-center text-blue-500 cursor-pointer hover:text-blue-700 transition duration-300"
          >
            View More
          </span>
        ) : (
          <li></li>
        )}
      </ul>
    </div>
  );

  // Add this function to handle double click
  const handleGoldenBatchClick = (batch: any) => {
    dispatch(setSelectedBatchDetails(batch));
    setDataContentSection("golden-parameters-details");
  };

  const handleDeleteConfirm = async () => {
    try {
      if (fileToBeDeleted) {
        const response = await deleteRequest(
          `/file/${fileToBeDeleted?.csv_id}`
        );
        console.log("response", response);
        if (response.data.status === 200) {
          Notiflix.Notify.success("File successfully deleted");
          setShowConfirmDeleteModel(false);
          // fetchFileList();
          setFileList((prevFileList) =>
            prevFileList.filter(
              (file: any) => file.csv_id !== fileToBeDeleted.csv_id
            )
          );
          setUploadedFiles((prevUploadedFiles: any) =>
            prevUploadedFiles.filter(
              (file: any) => file.csv_id !== fileToBeDeleted.csv_id
            )
          );
        }
      }
    } catch (error: any) {
      console.error("Error deleting file:", error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDeleteModel(false);
  };

  const camelCaseToWords = (str: string) => {
    let result = str.split('').map((char, index) => {
      if (char === char.toUpperCase() && index !== 0) {
        return ' ' + char;
      }
      return char;
    }).join('');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  if (selectedFile) {
    return (
      <div className="dataContent">
        <div className="data-outer">
          <div className="flex font-medium items-center line-clamp-1 gap-2 !text-xs !text-[#252963]">
            <Button
              onClick={handleBackClick}
              type="text"
              className="p-0  !gap-0 !bg-transparent"
            >
              {" "}
              <img src={arrowleft} />
            </Button>
            <span>{selectedFile}</span>
          </div>
        </div>

        <div className="data-outer">
          <DynamicSubPlotConfig availableColumns={availableColumns} originalFileData={originalFileData} setDynamicPlotData={setDynamicPlotData} setDynamicPlotLayout={setDynamicPlotLayout} />
          {/* <Title
            level={5}
            className="text-black text-base font-semibold border-t border-solid border-[#D9D9D9] mt-4 pt-4 !mb-4"
          >
            Dynamic Plot
          </Title> */}
          {/* <Checkbox.Group
            value={selectedColumns}
            onChange={handleColumnChange}
            className="column-checkbox-group"
          >
            {availableColumns.map((column) => (
              <div key={column} className="checkbox-item">
                <Checkbox value={column}>{column}</Checkbox>
              </div>
            ))}
          </Checkbox.Group> */}
        </div>
      </div>
    );
  }

  const handleReportTable = (tableName: any) => {
    navigate(`/?tab=data&report-table=true&table=${tableName}`);
    setDataContentSection("data-visualisation");
    setFilteredFileData([]);
  }

  return (
    <React.Fragment>
      <div className="dataContent">
        <Title level={4}>Connected Data Source</Title>
        <div className="data-outer">
          <div className="connect-data active">
            <img src={file} alt="file" />
            Files
          </div>
          {!loadingFiles ? (
            <> {renderFileList()} </>
          ) : (
            <>
              <ul>
                <li>
                  <span style={{ fontSize: "16px" }}>Loading ...</span>
                </li>
              </ul>
            </>
          )}
        </div>

        <div className="data-outer">
          <div className="connect-data">
            <img src={file} alt="file" />
            MS SQL
          </div>
        </div>

        <div className="seprater"></div>

        <Title level={4}>Available Data Source</Title>

        <div className="data-source">
          <ul>
          <li
              onClick={() => setIsDateRangeVisible(!isDateRangeVisible)} // Toggle visibility on click
            >
              <Flex
                justify="space-between"
                align="center"
                gap={10}
                className="cursor-pointer"
              >
                <Space>
                  <span>
                    <img src={file} alt="file" />
                  </span>
                  <Paragraph>MS SQL</Paragraph>
                </Space>
                <Flex className="action-btn">
                  <Button disabled={isFileUploaded}>
                    <img src={plus} alt="plus" />
                  </Button>
                </Flex>
              </Flex>

            {isDateRangeVisible && (
              <>
                <div className="date-range-selection"  >
                  <Space className="cursor-pointer" onClick={() => handleReportTable('vat_report_data')}>
                    <Paragraph>Vat Report Data</Paragraph>
                  </Space>
                </div>
                <div className="date-range-selection"  >
                  <Space className="cursor-pointer" onClick={() => handleReportTable('cheese_block_report')}>
                    <Paragraph>Cheese Block Report</Paragraph>
                  </Space>
                </div>
              </>
            )}
            </li>

            {["Influx DB", "Files (CSVs)", "PLC"].map(
              (source, index) => (
                <li onClick={showModal} key={index}>
                  <Flex
                    justify="space-between"
                    align="center"
                    gap={10}
                    className="cursor-pointer"
                  >
                    <Space>
                      <span>
                        <img src={file} alt="file" />
                      </span>
                      <Paragraph>{source}</Paragraph>
                    </Space>
                    <Flex className="action-btn">
                      <Button disabled={isFileUploaded}>
                        <img src={plus} alt="plus" />
                      </Button>
                    </Flex>
                  </Flex>
                </li>
              )
            )}
          </ul>
        </div>

        <div className="seprater"></div>
        <div className="flex items-center justify-between mb-4">
          <Title level={4} className="!mb-0">
            Golden Parameters
          </Title>
          <button
            onClick={handleGoldenParameter}
            className="bg-transparent border-none cursor-pointer hover:opacity-80"
          >
            <img src={filter} alt="filter" className="w-5 h-5" />
          </button>
        </div>
        <div className="data-outer">
          <div className="connect-data active">
            <img src={file} alt="file" />
            Batches
          </div>
          {renderGoldenBatchList()}
        </div>


        <div className="seprater"></div>
        <div className="flex items-center justify-between mb-4">
          <Title level={4} className="!mb-0">
            Material Parameters
          </Title>
          <button
            onClick={handleMaterialParameter}
            className="bg-transparent border-none cursor-pointer hover:opacity-80"
          >
            <img src={filter} alt="filter" className="w-5 h-5" />
          </button>
        </div>
        <div className="data-outer">
          {/* <div className="connect-data active">
            <img src={file} alt="file" />
            Heated Milk
          </div> */}
          {renderMaterialBatchList()}
        </div>
      </div>

      <Modal
        title="Upload Files"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mb-4">
          <div className="font-medium mb-2">File Type:</div>
          <Radio.Group 
            value={fileTypes.length === 2 ? 'both' : fileTypes[0]} 
            onChange={e => {
              if (e.target.value === 'both') {
                setFileTypes(['processes', 'material']);
              } else {
                setFileTypes([e.target.value]);
              }
            }}
            className="flex gap-4"
          >
            <Radio value="processes">Process</Radio>
            <Radio value="material">Material</Radio>
            <Radio value="both">Both</Radio>
          </Radio.Group>
        </div>

        <Upload
          className="upload-btn"
          multiple
          {...uploadProps}
          fileList={selectedFiles}
        >
          <Button icon={<UploadOutlined />}>Click or Drag to Upload CSV</Button>
        </Upload>
        <div style={{ marginTop: "10px", color: "#666" }}>
          Note: Only CSV files are supported
        </div>
        <button
          className="w-full bg-primary text-white p-2 rounded mt-4"
          onClick={() => handleUploadAllClick()}
        >
          Upload all
        </button>
      </Modal>

      <ConfirmDeleteModal
        type={"File"}
        visible={showConfirmDeleteModel}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={fileToBeDeleted?.file_name as string}
      />
    </React.Fragment>
  );
}

export default DataTab;
