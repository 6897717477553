import { Table, Spin, Drawer, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Histogram } from "../../charts/Histogram";
import DataTable from "../../tables/DataTable";
import {
  deleteRequest,
  getRequest,
  postRequest,
  uploadFileRequest,
} from "../../../utils/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import GoldenValueTable from "../../tables/GoldenValueTable";
import GoldenValueDetail from "../GoldenValueDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  setGoldenBatches,
  setLoading,
  setError,
} from "../../../Redux/slices/goldenBatchSlice";
import DeleteIcon from "../../../img/trash-icon.svg";
import Notiflix, { Notify } from "notiflix";
import ConfirmDeleteModal from "../../Modal/ConfirmDeleteModal";
import CreateParameter from "./createParameter";
import HandsonDataTable from "../../tables/HandsonDataTable";
import exclametryMark from '../../../img/exclametrymark.svg'
import { json } from 'stream/consumers';
import FileMapping from '../../tables/FileMapping';
import { formatDate } from "../../Common/dateFormatter";
import { DynamicSubPlot } from "../../charts/DynamicSubPlot";

import StaticalAnalysisTable from "../../tables/StatisticalAnalysisTable";
import MaterialValueTable from "../../tables/MaterialValueTable";

interface Props {
  dataContentSection: string;
  setDataContentSection: any;
  handleFileDoubleClick: (csvId: string) => void;
  file: string;
  uploadedFiles: Array<any>;
  filteredFileData: any;
  setUploadedFiles: any;
  setFilteredFileData: (data: any) => void;
  dynamicPlotData: any;
  setDynamicPlotData: any;
  dynamicPlotLayout: any;
  setDynamicPlotLayout: any;
}

const DataTabContent: React.FC<Props> = ({
  dataContentSection,
  setDataContentSection,
  handleFileDoubleClick,
  file,
  uploadedFiles,
  filteredFileData,
  setUploadedFiles,
  setFilteredFileData,
  dynamicPlotData,
  setDynamicPlotData,
  dynamicPlotLayout,
  setDynamicPlotLayout
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [goldenBatchNames, setgoldenBatchNames] = useState<any>([]);
  const [totalGoldenDataCount, setTotalGoldenDataCount] = useState<any>(0);
  const [goldenDataPageNo, setGoldenDataPageNo] = useState<any>(1);
  const dispatch = useDispatch();
  const [fileToBeDeleted, setFileToBeDeleted] = useState<any>(null);
  const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const selectSystems = useSelector((state: any) => state.systems.systems);
  const [open, setOpen] = useState(false);
  const [draggedFile, setDraggedFile] = useState(null);
  const [materialList, setMaterialList] = useState([])
  const [datadata , setData] = useState({})
  const params = new URLSearchParams(location.search);
  let reportTable = params.get("report-table");
  const { RangePicker } = DatePicker;
  const [selectedDateRange, setSelectedDateRange] = useState<any>(null); // State to store selected date range
  const [viewReportTable, setViewReportTable] = useState<boolean>(false);
  const [isEmptyState, setIsEmptyState] = useState(false); // State to track empty state

  useEffect(() => {
    if(!params.get("report-table")) {
      setViewReportTable(false)
      setIsEmptyState(false)
    }
  }, [params])
  
  const getGolenBatchData = async (filters?: any) => {
    const params = new URLSearchParams(location.search);
    const workflowId = params.get("workflowId");
    try {
      dispatch(setLoading(true));
      const response = await getRequest(`/golden-data/${workflowId}`, {
        filters,
      });
      if (response?.data?.data) {
        if (!filters) {
          dispatch(setGoldenBatches(response.data.data));
        }
        setgoldenBatchNames(response?.data?.data);
      }
      if (response?.data) {
        setTotalGoldenDataCount(
          response?.data?.totalCount ? response?.data?.totalCount : 0
        );
      }
      if (response?.data?.page) {
        setGoldenDataPageNo(response?.data?.page);
      }
    } catch (error: any) {
      console.error("Error fetching golden batch data:", error);
      dispatch(setError(error.message || "Failed to fetch golden batch data"));
    } finally {
      dispatch(setLoading(false));
    }
  };


  const getMaterialBatch = async () =>{
    try {

      const response = await getRequest('/bucketization/get-statistical-data')
      if (response.data.status === 200) {
        setMaterialList(response?.data?.data);
        console.log('response?.data?.data :', response?.data?.data);
        let data ={
          clustered_agg:[]
        } 
        data.clustered_agg = response?.data?.data[0].bucketization_statistical_value
        // setData(data.clustered_agg)
      }
      
    } catch (error) {
      
    }
  }

  const showDeleteConfirmModel = (file: any) => {
    console.log("file", file);
    setShowConfirmDeleteModel(true);
    setFileToBeDeleted(file);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (fileToBeDeleted) {
        const response = await deleteRequest(
          `/file/${fileToBeDeleted?.csv_id}`
        );
        console.log("response", response);
        if (response.data.status === 200) {
          Notiflix.Notify.success("File successfully deleted");
          setShowConfirmDeleteModel(false);
          setUploadedFiles((prevFiles: any) =>
            prevFiles.filter(
              (file: any) => file.csv_id !== fileToBeDeleted.csv_id
            )
          );
          // fetchFileList();
        }
      }
    } catch (error) {
      console.error("Error deleting workflow:", error);
    }
  };

  const handleDeleteCancel = () => {
    setShowConfirmDeleteModel(false);
  };
  const openDrawer = (record: any) => {
    if (record.compatibility == false) {
      if (!selectSystems[0]?.systems?.length) {
        Notify.warning('Please Select The Corresponding Systems of File', { timeout: 2000, distance: '50px', clickToClose: true })
        return
      }
      showDrawer(record)
      return
    } else {
      handleFileDoubleClick(record.csv_id);
    }

  }

  const showDrawer = (file: any) => {
    setOpen(true);
    setDraggedFile(file)
  };
  const onClose = () => {
    setOpen(false);
  };

  const fetchUploadedFiles = () => {
    if (draggedFile != null) {
      setUploadedFiles((prevFiles: any[]) =>
        prevFiles.map((file: any) =>
          file.csv_id == (draggedFile as any)?.csv_id
            ? { ...file, compatibility: true } // Update compatibility for the matched file
            : file // Keep other files unchanged
        )
      );

    }
  }
  useEffect(() => {
    getGolenBatchData();
  }, []);

  useEffect(() => {
    if (filteredFileData) {
      const timer = setTimeout(() => {
        setIsDataLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [filteredFileData]);

  useEffect(() => {
    if (dataContentSection === "data-visualisation") {
      setIsDataLoading(true);
    }
    if(dataContentSection === "material-parameters"){
      getMaterialBatch()
    }
    
    console.log('reportTable', reportTable)
    if(reportTable)
      setViewReportTable(true)

  }, [dataContentSection]);


  const handleDataChange = (newData: any[]) => {
    setTableData(newData);
  };

  const createCSVFile = (data: any[], fileName: string) => {
    // Get headers from columns
    const headers = Object.keys(data[0]);

    // Create CSV content with explicit headers
    const csvContent = [
      headers.join(","), // Header row
      ...data.map((row) =>
        headers
          .map((header) => {
            let value = row[header];
            // Handle special characters and formatting
            if (value === null || value === undefined) return "";
            if (typeof value === "string") {
              if (value.includes(",") || value.includes('"')) {
                return `"${value.replace(/"/g, '""')}"`;
              }
              // Convert string to integer if possible
              const intValue = parseInt(value, 10);
              if (!isNaN(intValue)) {
                value = intValue;
              }
            }
            return value;
          })
          .join(",")
      ),
    ].join("\n");

    // Check if fileName ends with '.csv', and if not, add it
    const formattedFileName = fileName?.endsWith(".csv")
      ? fileName
      : `${fileName}.csv`;

    return new File([csvContent], formattedFileName, {
      type: "text/csv;charset=utf-8;",
    });
  };

  const uploadFile = async (file: File, description: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);
    formData.append("workflowId", "0");
    formData.append("systems", JSON.stringify(selectSystems[0]?.systems || []));
    formData.append("compatibility", "true");

    // Get the parent file ID from localStorage
    const savedFile = localStorage.getItem("selectedFile");
    const parentFileId = savedFile ? JSON.parse(savedFile).csv_id : "";
    formData.append("parent_file_id", parentFileId);

    return await uploadFileRequest("/file/upload", formData);
  };

  const updateFileOnServer = async (csvId: string, csvFile: File) => {
    const savedFile = localStorage.getItem("selectedFile");
    if (!savedFile) {
      Notiflix.Notify.failure("No file selected to save");
      return;
    }

    const fileObject = JSON.parse(savedFile);

    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("description", "File updated from table editor");
    formData.append("workflowId", "0"); // You can modify this based on your requirements
    formData.append("systems", JSON.stringify(selectSystems[0]?.systems || []));
    formData.append("compatibility", "true");
    formData.append("csv_id", csvId); // Pass the csv_id to update the existing file

    try {
      const response = await uploadFileRequest("/file/update", formData);

      if (response.data.status === 200) {
        // Update the file list with the updated file
        const updatedFiles = uploadedFiles.map((file: any) =>
          file.csv_id === fileObject.csv_id ? response.data.data : file
        );
        setUploadedFiles(updatedFiles);

        // Update local storage with new file details
        localStorage.setItem(
          "selectedFile",
          JSON.stringify(response.data.data)
        );
        return response;
      }
    } catch (error) {
      console.error("Error updating file:", error);
      Notiflix.Notify.failure("Failed to save file");
    }
  };


  const handleSave = async (data: any[]) => {
    try {
      const savedFile = localStorage.getItem("selectedFile");
      if (!savedFile) {
        Notiflix.Notify.failure("No file selected to save");
        return;
      }

      const fileObject = JSON.parse(savedFile);
      console.log('tableData', tableData)
      const csvFile = createCSVFile(tableData, fileObject.file_name);

      // Make API call to update the file on the server
      console.log('fileObject', fileObject)
      const response = await updateFileOnServer(fileObject.csv_id, csvFile);

      if (response?.data?.status === 200) {
        Notiflix.Notify.success("File saved successfully");

        // Update the file list and local storage
        const updatedFiles = uploadedFiles.map((file: any) =>
          file.csv_id === fileObject.csv_id ? response.data.data : file
        );
        setUploadedFiles(updatedFiles);

        // Update local storage with new file details
        localStorage.setItem("selectedFile", JSON.stringify(response.data.data));
      }
    } catch (error) {
      console.error("Error saving file:", error);
      Notiflix.Notify.failure("Failed to save file");
    }
  };

  const handleSaveAs = async (fileName: string, data: any[]) => {
    try {
      const csvFile = createCSVFile(data, fileName);
      const response = await uploadFile(csvFile, "File saved from table editor");

      if (response.data.status === 200) {
        Notiflix.Notify.success("File saved successfully");
        const updatedFiles = [...uploadedFiles, response.data.data];
        setUploadedFiles(updatedFiles);
      }
    } catch (error) {
      console.error("Error saving file:", error);
      Notiflix.Notify.failure("Failed to save file");
    }
  };

  const handleBack = () =>{
    setDataContentSection('material-parameters')
  }

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDateRange(dateStrings); // Update selected date range
  };

  const handleOkayClick = async () => {
    console.log("Selected Date Range:", selectedDateRange);
  
    if (!selectedDateRange || !selectedDateRange[0] || !selectedDateRange[1]) {
      Notify.failure("Please select both start and end dates.");
      return;
    }

    const tableName = params.get("table")
    console.log('tableName', tableName)
  
    let payload = {
      start_date: selectedDateRange[0],
      end_date: selectedDateRange[1],
      tableName,
    };
  
    try {
      let response = await postRequest("/file/report-data", payload);
      console.log("API Response:", response);
  
      if (response.data.data && response.data.data.length > 0) {
        const fileData = response.data.data;
  
        const cleanedData = fileData.map((row: any) => {
          const cleanRow = { ...row };
          Object.keys(cleanRow).forEach((key) => {
            if (key === "Time" && cleanRow[key]) {
              cleanRow[key] = cleanRow[key];
            } else if (key === "DateTime" && cleanRow[key]) {
              cleanRow[key] = cleanRow[key];
            } else if (typeof cleanRow[key] === "string") {
              const num = parseFloat(cleanRow[key]);
              cleanRow[key] = isNaN(num) ? cleanRow[key] || 0 : num;
            }
          });
          return cleanRow;
        });
  
        setFilteredFileData(cleanedData);
        setIsEmptyState(false); // Data exists, so no empty state
        console.log("Cleaned Data:", cleanedData);
      } else {
        setFilteredFileData([]); // Clear any existing data
        setIsEmptyState(true); // Set empty state
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Notify.failure("Failed to fetch data. Please try again.");
      setIsEmptyState(true); // Set empty state on error
    }
  };

  const renderFileListing = () => (
    <div className="p-8">
      <h2 className="font-medium text-[#383838] text-[29px] leading-[34px] mb-8">
        Files
      </h2>
      <div className="files-table-container relative">
        <div className="text-[#4D4D4D] leading-[21px] text-lg font-semibold mb-2.5 absolute top-[60px] z-10">
          Connected files
        </div>
        <Table
          className="files-table first-table"
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
              render: (text: any, record: any) => (
                <span
                  style={{ cursor: "pointer" }}
                  className="flex gap-2.5 items-center"
                  onDoubleClick={() => {
                    openDrawer(record)
                  }}
                >
                  <img src={record.compatibility === false ? exclametryMark : file} alt="file icon" /> {text}
                </span>
              ),
            },
            {
              title: "File Type",
              dataIndex: "type_of_file",
              key: "type_of_file",
              render: (types: string[]) => types?.join(', ')
            },
            {
              title: "Owner",
              dataIndex: "owner",
              key: "owner",
            },
            {
              title: "Created",
              dataIndex: "created",
              key: "created",
            },
            {
              title: "Last updated",
              dataIndex: "lastUpdated",
              key: "lastUpdated",
            },
            {
              title: "Delete File",
              dataIndex: "deleteFile",
              key: "deleteFile",
              render: (text: any, record: any) => (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => showDeleteConfirmModel(record)}
                >
                  <img src={DeleteIcon} alt="Delete" className="size-4" />
                </span>
              ),
            },
          ]}
          dataSource={[
            { key: "blank" },
            ...uploadedFiles.map((file: any) => ({
              key: file.csv_id,
              csv_id: file.csv_id,
              name: file.file_name,
              type_of_file: file.type_of_file,
              owner: `${file.owner?.first_name} ${file.owner?.last_name}`,
              created: formatDate(file.created_at),
              lastUpdated: formatDate(file.updated_at),
              deleteFile: "Delete",
              compatibility: file.compatibility,
              file_path: file.file_path
            })),
          ]}
          pagination={false}
        />
      </div>
      <Drawer width={'40%'} title={
        <div className="flex flex-col gap-2">
          Match Missing Fields
          <span style={{ fontSize: '12px', color: '#888' }}> Map your CSV column with required fields</span>
          <div className="h-[5px] mt-2 bg-slate-600 bg-opacity-10 w-full rounded-md"></div>
        </div>
      } onClose={onClose} open={open} className="[&_.ant-drawer-body]:px-[30px] [&_.ant-drawer-header-title]:flex-row-reverse">
        {open &&
          <FileMapping configurations={selectSystems} file={draggedFile} fetchUploadedFiles={fetchUploadedFiles} setOpen={setOpen} />
        }
      </Drawer>
    </div>
  );

  const renderDataVisualisation = () => (
    <div className="inner-box flex flex-col">
      {viewReportTable && (
        <div className="data-table">
          <div >
            <div className="flex flex-col gap-4 p-4 bg-gray-100 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-gray-700">Select Date Range</h3>
              <div className="flex gap-4">
                <RangePicker
                  onChange={handleDateRangeChange} // Handle date range selection
                  className=" border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
                />
                <button
                  onClick={handleOkayClick}
                  className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition duration-300"
                >
                  Fetch
                </button>
              </div>
            </div>
          </div>
        </div>
      )} 
      <div className="data-table">
        {isDataLoading && !viewReportTable ? (
          <div className="flex justify-center items-center min-h-[400px]">
            <Spin
              size="large"
              tip="Loading visualization..."
              className="custom-spinner"
            />
          </div>
        ) : isEmptyState ? (
          <div className="flex flex-col items-center justify-center min-h-[400px]">
            {/* <img
              src="/path/to/empty-state-image.svg" // Replace with your empty state image
              alt="No Data"
              className="w-40 h-40 mb-4"
            /> */}
            <p className="text-gray-500 text-lg">No data available for the selected date range.</p>
          </div>
        )  : (
          <>
            {filteredFileData && (
              <>
                <div className="mb-8">
                  {/* <Histogram data={filteredFileData} /> */}
                </div>
                <div>
                  <DynamicSubPlot dynamicPlotData={dynamicPlotData} setDynamicPlotData={setDynamicPlotData} dynamicPlotLayout={dynamicPlotLayout} setDynamicPlotLayout={setDynamicPlotLayout} />
                </div>
                <div>
                  <HandsonDataTable
                    data={filteredFileData}
                    onDataChange={(newData) => {
                      setTableData(newData);
                    }}
                    onSave={handleSave}
                    onSaveAs={handleSaveAs}
                    viewReportTable={viewReportTable}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>

    </div>
  );

  const renderGoldenParameters = () => (
    <div className="inner-box">
      <GoldenValueTable
        goldenBatchNames={goldenBatchNames}
        setDataContentSection={setDataContentSection}
        setgoldenBatchNames={setgoldenBatchNames}
        uploadedFiles={uploadedFiles}
        getGolenBatchData={getGolenBatchData}
        totalGoldenDataCount={totalGoldenDataCount}
        goldenDataPageNo={goldenDataPageNo}
        setIsEdit={setIsEdit}
      />
    </div>
  );
  const renderMaterialParameters = () => (
    <div className="inner-box">
      <MaterialValueTable data={materialList}
      setDataContentSection={setDataContentSection}
      setData={setData}
      />
    </div>
  );

  const renderCreateParameters = () => (
    <div className="inner-box">
      <CreateParameter setDataContentSection={setDataContentSection} isEdit={isEdit} setIsEdit={setIsEdit} />
    </div>
  );
  const renderGoldenParametersDetails = () => {
    return (
      <>
        <GoldenValueDetail setDataContentSection={setDataContentSection} />
      </>
    );
  };
  const renderMaterialParametersDetails = () => {
    return (
      <>
        <div className="golden-values p-5 w-full">
    <div className="relative">
    <div className="flex items-center gap-4 mb-4">
          <button
          onClick={handleBack}
          >
            <svg
              className="h-5 w-5 rotate-90"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
          <h2 className="!font-medium !text-[32px] !text-[#333] worksans">
            Material Parameters
          </h2>
  </div>

      <StaticalAnalysisTable clustered_agg={datadata} isMaterial={true}/>
    </div>
    </div>
      </>
    );
  };

  return (
    <div>
      {dataContentSection === "file-listing" && renderFileListing()}
      {dataContentSection === "data-visualisation" && renderDataVisualisation()}
      {dataContentSection === "golden-parameters" && renderGoldenParameters()}
      {dataContentSection === "create-parameters" && renderCreateParameters()}
      {dataContentSection === "golden-parameters-details" &&
        renderGoldenParametersDetails()}
      {dataContentSection === "material-parameters" && renderMaterialParameters()}
      {dataContentSection === "material-parameters-details" &&
        renderMaterialParametersDetails()}
      <ConfirmDeleteModal
        type={"File"}
        visible={showConfirmDeleteModel}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        name={fileToBeDeleted?.name as string}
      />
    </div>
  );
};

export default DataTabContent;
