import Link from "antd/es/typography/Link";
import React, { useState, useEffect, useRef } from "react";
import logo from "../../img/logo-new.svg";
import share from "../../img/ShareNetwork-r.svg";
import setting from "../../img/settings.svg";
import user from "../../img/user.svg";
import analytic from "../../img/analytic.svg";
import taskIcon from "../../img/tasksIcon.svg";
import logoutIcon from "../../img/logout.svg";
import settingIcon from "../../img/setting.svg";
import chart from "../../img/chart1.svg";
import { useAuth } from "../../context/AuthContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getRequest, postRequest, patchRequest } from "../../utils/apiHandler";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  addSystem,
  removeSystem,
  setSystems,
  clearSystems,
} from "../../Redux/slices/systemsSlice";
import Notiflix, { Notify } from "notiflix";

function Header() {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [workflowId, setWorkflowId] = useState<string>("");
  const [userData, setUserData] = useState<any>(null);
  const { logout, authState } = useAuth();
  const location = useLocation();
  const timeoutRef = useRef<number | null>(null);
  const [openGlobalSettings, setOpenGlobalSettings] = useState(false);
  const navigate = useNavigate();
  const intervalRef = useRef<number | null>(null);
  const [isUserActive, setIsUserActive] = useState(false);
  const dispatch = useDispatch();
  const [systemsOptions, setSystemsOptions]: any = useState<any>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isSystemSelected, setIsSystemSelected] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { systems } = useSelector((state: any) => state.systems);
  console.log('systemsssss', systems)

useEffect(() => {
  if (authState?.user?.role?.alias=="shift_supervisor") {
    navigate("/tasks", { replace: true }); // ✅ Correct way to navigate
  }

  const storedSystems = localStorage.getItem("selectedSystems");
  if (storedSystems) {
    const systemsData = JSON.parse(storedSystems);
    // console.log('systemsData :', systemsData);
    const selectedNames = systemsData.map(
      (system: { systems: string }) => system.systems
    );

    if (selectedNames[0]?.length) {
      setSelectedItems(
        selectedNames[0].map(
          (system: { systemName: string }) => system.systemName
        )
      );
      dispatch(setSystems(systemsData));
      setIsSystemSelected(true);
    } else {
      setIsSystemSelected(false);
    }
  } else {
    setIsSystemSelected(false);
  }
  getGlobalConfigSystems();
}, []);

  useEffect(() => {
    const initializeSystem = () => {      
      if (authState?.user?.selected_systems?.length > 0) {
        // If selected systems exist in useAuth, set those systems
        const selectedSystems = systemsOptions.filter((option: any) =>{
          return authState?.user?.selected_systems.includes(option.systemId)
        }
        );
        // if (selectedSystems.length > 0) {
        //   handleChange(selectedSystems);
        // }
      } 
      else if (systemsOptions.length > 0) {
        const firstOption = [systemsOptions[0]];
                handleChange(firstOption);
        handlefirstoption(firstOption)
      }
      setIsSystemSelected(true);
      setOverlayVisible(false);
    };

    initializeSystem();
  }, [authState, systemsOptions]);

  const handlefirstoption = (firstOption:any) =>{
  console.log('handlefirstoption :', firstOption);
  const selectedSystemsData = [
    {
      systems: firstOption.map(({ data, systemId, label }: any) => ({
        systemId: data?.systemId || systemId, // Ensure systemId is taken from either data or directly
        systemName: data?.label || label, // Ensure label is assigned properly
      })),
      config: firstOption.map(({ data }: any) => data?.configurations || null),
    },
  ];
  
  dispatch(setSystems(selectedSystemsData));
  localStorage.setItem("selectedSystems", JSON.stringify(selectedSystemsData));
  }

useEffect(() => {
  const isProcessEngineer = authState.user?.role?.alias === "process_engineer";
  // Show overlay if no system is selected, except for the specific route
   const isSettingsRoute = location.pathname === "/settings";
   const isOnboardingRoute = location.pathname.startsWith("/onboarding");

   // Determine whether to show the overlay
   const showOverlay =
     !isSystemSelected &&
     !isOnboardingRoute &&
     (!isSettingsRoute || (isSettingsRoute && !isProcessEngineer));

   // Set the overlay visibility
   setOverlayVisible(showOverlay);
}, [isSystemSelected, location]);

  const getGlobalConfigSystems = async () => {
    let options: any[] = [];
    const response = await getRequest("/configurations/get-configuration");
    if (response?.data?.data) {
      // const systems = Object.keys(response.data.data)
      // for( let systemData in response?.data?.data){
      // console.log('systemData :', systemData);

      // }
      let systems = [];
      for (const systemName in response?.data?.data) {
        if (response?.data?.data.hasOwnProperty(systemName)) {
          const systemId = response?.data?.data[systemName].systemId
            ? response?.data?.data[systemName].systemId
            : null;
          systems.push({ systemName, systemId });
        }
      }

      if (systems && systems.length) {
        systems.map((res) => {
          options.push({
            systemId: res?.systemId ? res.systemId : null,
            value: res.systemName,
            label: res.systemName,
            data: response.data.data[res?.systemName],
          });
        });
        setSystemsOptions(options);
      }
      if (response?.data?.selectedSystems) {
        console.log('response.data.selectedSystems', response.data.selectedSystems);
        const selectedOptionData = options
          .filter((option: any) => response.data.selectedSystems.includes(option.systemId))
          .map((option: any) => ({
            name: {
              systemId: option.systemId,
              systemName: option.value,
            },
            config: option.data,
          }));
  
        // Prepare the data to be saved in localStorage and Redux
        console.log('selectedOptionData', selectedOptionData);
        const selectedSystemsData = [
          {
            systems: selectedOptionData.map(({ name }: any) => ({
              systemId: name.systemId,
              systemName: name.systemName,
            })),
            config: selectedOptionData.map(({ config }: any) => config),
          },
        ];
  
        // Dispatch the selected systems along with their configurations and systemId (inside name)
        dispatch(setSystems(selectedSystemsData));
  
        // Store the selected systems in localStorage with systemId and systemName inside name
        localStorage.setItem("selectedSystems", JSON.stringify(selectedSystemsData));
  
        setSelectedItems(selectedOptionData.map(({ name }: any) => name.systemName));
        
        // Update authState with selected systems if authState.user is not null
        if (authState?.user) {
          authState.user.selected_systems = selectedOptionData.map(({ name }: any) => name.systemId);
        }
      }

    }
  };

  useEffect(() => {
    console.log('selectedItems', selectedItems)
  }, [selectedItems, setSelectedItems])


  const showDrawer = () => {
    setTooltipVisible(false);
    navigate("/settings");
  };

  const onClose = () => {
    setOpenGlobalSettings(false);
  };

  // Check if we're on an onboarding route
  const isOnboardingRoute = location.pathname.startsWith("/onboarding");

  const updateLastLogin = async () => {
    try {
      console.log("Last used updated");
      let payload = {
        last_used: new Date(),
      };
      // return

      const response = await postRequest("/activityLog", payload);
      console.log("response", response);
    } catch (error) {
      console.error("Error updating last login:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resetInactivityTimeout = () => {
    setIsUserActive(true); // Mark user as active on interaction
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsUserActive(false); // Set to inactive after timeout
    }, 300000); // 5 minutes
    // }, 60000); // 1 minutes
  };

  useEffect(() => {
    const handleMouseMove = () => {
      resetInactivityTimeout();
    };

    window.addEventListener("mousemove", handleMouseMove);

    intervalRef.current = window.setInterval(() => {
      if (isUserActive && userData && userData?.onboarding == "completed") {
        updateLastLogin();
      }
    }, 300000); // 5 minutes
    // }, 60000); // 1 minutes

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isUserActive]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setUserData(userData);
    }
    // Get workflowId from URL parameters
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get("workflowId") || "";
    setWorkflowId(id);
  }, [location.search]);

  useEffect(() => {
    // Only set up timer if not on onboarding route
    if (!isOnboardingRoute) {
      const timer = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 60000);

      return () => clearInterval(timer);
    }
  }, [isOnboardingRoute]);

  const toggleTooltip = () => {
    setTooltipVisible(!isTooltipVisible);
  };

  const handleLogout = () => {
    logout();
    setTooltipVisible(false);
    removeSelectedSystems();
  };
  // const handleChange = (value: any) => {
  // const selectedOptionData = systemsOptions.filter((option: { value: any; }) =>
  // value.includes(option.value)
  // ).map((option: { value: any; data: any; }) => ({
  //   name: option.value,  // Add the name from option.value
  //   config: option.data  // Add the corresponding config data
  // }));
  // console.log('selectedOptionData :', selectedOptionData);
  // setSelectedItems(value);
  //   dispatch(setSystems([
  //     { name: value, config: selectedOptionData},
  //   ]));
  //   localStorage.setItem('selectedSystems', JSON.stringify([
  //     { name: value, config: selectedOptionData},
  //   ]));
  // };

  const handleChange = async (value: any) => {
    const selectedOptionData = systemsOptions
      .filter((option: { value: any }) => value.includes(option.value))
      .map((option: { value: any; data: any; systemId: any }) => ({
        name: {
          systemId: option.systemId,
          systemName: option.value,
        },
        config: option.data,
      }));

    if (selectedOptionData?.length < 1) {
      setOverlayVisible(true);
      setIsSystemSelected(false);
    } else {
      setOverlayVisible(false);
      setIsSystemSelected(true);
    }

    // Update selectedItems state
    // Update selectedItems state
    setSelectedItems(value);
  
    // Prepare the data to be saved in localStorage and Redux
    const selectedSystemsData = [
      {
        systems: selectedOptionData.map(({ name }: any) => ({
          systemId: name.systemId,
          systemName: name.systemName,
        })),
        config: selectedOptionData.map(({ config }: any) => config),
      },
    ];
  
    // Dispatch the selected systems along with their configurations and systemId (inside name)
    dispatch(setSystems(selectedSystemsData));
  
    // Store the selected systems in localStorage with systemId and systemName inside name
    localStorage.setItem("selectedSystems", JSON.stringify(selectedSystemsData));
  
    // Update authState with selected systems if authState.user is not null
    if (authState?.user) {
      authState.user.selected_systems = selectedOptionData.map(({ name }: any) => name.systemId);
    }
    
    // Hit the API to update selected systems in the database
    if (selectedOptionData.length > 0) {
      try {
        const response = await patchRequest(
          `/auth/${authState?.user?.id}/update-selected-systems`,
          {
            selected_systems: selectedOptionData.map(({ name }: any) => ({
              systemId: name.systemId,
              systemName: name.systemName,
            })),
          }
        );
        if (response.status === 200) {
          console.log("Selected systems updated successfully:", response.data);
        }
      } catch (error) {
        console.error("Error making API call:", error);
      }
    }
  };

  const removeSelectedSystems = () => {
    localStorage.removeItem("selectedSystems");
    dispatch(setSystems([]));
    setSelectedItems([]);
  };
  const formatDateTime = (date: Date) => {
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  const handleKpiTracking = () => {
    navigate("/kpi-tracking");
  };

  // Check if user has access to KPI tracking
  const hasKpiAccess =
    authState.user?.role?.alias === "cio" ||
    authState.user?.role?.alias === "process_engineer";

  return (
    <React.Fragment>
      <div className="bg-primary py-3 pl-[18px] pr-8 tex-white flex justify-between items-center top-header sticky top-0 z-[999]">
        <Link href="/?tab=insight&workflowId=0">
          <img src={logo} alt="logo" />
        </Link>

        {!isOnboardingRoute && (
          <label className="text-white text-base flex gap-1">
            <span className="font-semibold">Book-{workflowId}</span>(
            {formatDateTime(currentDateTime)})
          </label>
        )}

        <div className="flex gap-5">
          {/* {userData?.onboarding == 'completed' && (
            <Link className="flex items-center gap-2 worksans font-medium text-base !text-white hover:opacity-70" href="/admin/user-overview">
              <img src={analytic} alt="admin" className="relative -top-px" />
              Analytics
            </Link>
          )}
          {  authState.user?.role?.rank <= 2 &&
              <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70" onClick={showDrawer}>
              <img src={settingIcon} alt="settings" />
              Settings
            </button>
          } */}

          {/* Remove this share button block
            <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70">
              <img src={share} alt="share" />
              Share
            </button>
            */}
          {/* {!isOnboardingRoute && (
            <>
              
              {hasKpiAccess && (
                <button 
                  className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70"
                  onClick={handleKpiTracking}
                >
                  <img src={chart} alt="kpi" className="relative -top-px" />
                  KPI Tracking
                </button>
              )}
            </>
          )} */}
          <div className="w-60 pr-3">
            <Select
              mode="tags"
              style={{
                width: "100%",
                borderColor: !isOverlayVisible ? undefined : "red",
                borderWidth: !isOverlayVisible ? undefined : "2px",
                borderStyle: !isOverlayVisible ? undefined : "solid",
                borderRadius: "8px",
              }}
              value={selectedItems}
              placeholder="Select Systems"
              onChange={handleChange}
              options={systemsOptions}
            />
          </div>

          <div className="relative inline-block" ref={dropdownRef}>
            <button
              className="flex gap-2 font-medium text-base text-white items-center hover:opacity-70"
              onClick={toggleTooltip}
            >
              <div className="size-[30px] bg-[#12164A] flex items-center justify-center rounded-full">
                <img src={user} alt="user" />
              </div>
              {authState.user?.first_name || "Guest"}
            </button>
            {isTooltipVisible && (
              <div className="absolute z-10 mt-2 w-40 -right-1 bg-white text-black rounded shadow-lg overflow-hidden">
                {authState.user?.role?.alias == "admin" && (
                  <Link
                    className="w-full flex items-center text-base worksans gap-2 !text-black text-left hover:bg-gray-200 !p-2 !border-b !border-solid !border-[#eee]"
                    href="/admin/user-overview"
                  >
                    <img
                      src={analytic}
                      alt="admin"
                      className="brightness-0 h-5"
                    />
                    Analytics
                  </Link>
                )}

                {authState.user?.role?.rank <= 2 && (
                  <button
                    className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2 !border-b !border-solid !border-[#eee]"
                    onClick={showDrawer}
                  >
                    <img
                      src={settingIcon}
                      alt="settings"
                      className="brightness-0 h-5"
                    />
                    Settings
                  </button>
                )}

                {!isOnboardingRoute && (
                  <>
                    {/* Remove this share button block
                    <button className="flex items-center gap-2 font-medium text-base text-white hover:opacity-70">
                      <img src={share} alt="share" />
                      Share
                    </button>
                    */}

                    {hasKpiAccess && (
                      <button
                        className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2 !border-b !border-solid !border-[#eee]"
                        onClick={handleKpiTracking}
                      >
                        <img
                          src={chart}
                          alt="kpi"
                          className="brightness-0 h-5 w-4 mr-1"
                        />
                        KPI Tracking
                      </button>
                    )}
                  </>
                )}

                <Link
                  className="w-full flex items-center text-base worksans gap-2 !text-black text-left hover:bg-gray-200 !p-2 !border-b !border-solid !border-[#eee]"
                  href="/tasks"
                >
                  <img
                    src={taskIcon}
                    alt="admin"
                    className="brightness-0 h-5"
                  />
                  Tasks
                </Link>

                <button
                  className="w-full flex items-center gap-2 text-left hover:bg-gray-200 p-2"
                  onClick={handleLogout}
                >
                  <img
                    src={logoutIcon}
                    alt="admin"
                    className="brightness-0 h-5"
                  />
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOverlayVisible && (
        <div className="overlay">
          <div className="overlay-content">
            <p>Please select at least one system to proceed.</p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Header;
